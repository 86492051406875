import '../assets/styles/dashboards/channels.css';
import React from 'react';
import ChannelCard from '../components/pure/channelCard';


const Channels = () => {
    return (
        <div className='channel-main'>
            <h1>Canales</h1>
                <div className="d-flex flex-wrap">

                    <ChannelCard
                        id = "avqtp"
                        name = "¿A vos que te parece?"
                        imgUrl = "https://i.ibb.co/DfK5cnJ/avqtp-logo.png"
                        bgImg = "black"
                    ></ChannelCard>

                    {/* <ChannelCard
                        id = "sinceramente"
                        name = "Sinceramente"
                        imgUrl = "https://i.ibb.co/DfK5cnJ/avqtp-logo.png"
                    ></ChannelCard> */}

                    <ChannelCard
                        id = "record"
                        name = "Record"
                        imgUrl = "https://i.ibb.co/vQk76KC/simple-260x290.png"
                        bgImg = "white"
                    ></ChannelCard>

            </div>
        </div>
    );
}

export default Channels;
