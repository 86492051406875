import './App.css';
import WardApp from './components/wardApp';


function App() {
  return (
    <div className="App">
      <WardApp></WardApp>
    </div>
  );
}

export default App;
