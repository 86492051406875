import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';


const ChannelCard = ({id, name, imgUrl, bgImg, description}) => {
    return (
        <div className="card border-danger">
            <div className='bg-img' style={{backgroundColor: bgImg}}>
                <img
                    src = {imgUrl}
                    className = "card-img-top"
                    alt = {`Logo de "${name}"`}
                />
            </div>
            <div className="card-body">
                <h5 className="card-title">{name}</h5>
                <p className="card-text">
                    {description != null && description}
                </p>
                <Link to={`/${id}`}>
                    <a href="#" className="btn btn-danger bg-black">Ver</a>
                </Link>
            </div>
        </div>
    );
}

ChannelCard.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
    bgImg: PropTypes.string,
    description: PropTypes.string,
};

export default ChannelCard;
