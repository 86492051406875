import '../assets/styles/dashboards/aboutUs.css';
import React from 'react';

const AboutUs = () => {
    return (
        <div>
            <h1>Sobre Nosotros</h1><br />
            <h2>Somos la agencia de marketing digital que te ayudará a crecer tu negocio</h2>
            
            <h3>
                ¿Estás cansado de que tu marketing no funcione? 
                ¿Quieres ver un aumento en el tráfico de tu sitio web, las conversiones y las ventas?
            </h3>    
            <p>
                Si es así, necesitas Grupo Ward.
                <br />Somos una agencia de marketing digital 
                especializada en ayudar a las empresas a crecer su negocio.
            </p>
            <h3>
                ¿Qué hacemos?
            </h3>
            <p>
                Ofrecemos una amplia gama de servicios de marketing digital, incluyendo:
                <ul>
                    <li>
                        <strong>Creación de contenido:</strong> Desarrollamos contenido de alta calidad que atrae y conecta con tu público objetivo.
                    </li>
                    <li>
                        <strong>Generación de leads:</strong> Generamos leads calificados que están listos para comprar tus productos o servicios.
                    </li>
                    <li>
                        <strong>Growth marketing:</strong> Desarrollamos estrategias de marketing para ayudar a tu empresa a crecer y escalar.
                    </li>
                </ul>
            </p>
            <h3>
                ¿Cómo lo hacemos?
            </h3>
            <p>
                Utilizamos las últimas tendencias del marketing digital para crear estrategias que funcionen. 
                <br />Nos apasiona ayudar a las empresas a alcanzar sus objetivos, y nos comprometemos a proporcionar 
                resultados.
            </p>
            <h3>
                ¿Quiénes somos?
            </h3>
            <p>
                Nuestro equipo está formado por profesionales experimentados que están al día 
                de las últimas tendencias del marketing digital.
                <br />Estamos apasionados por ayudar 
                a las empresas a crecer y alcanzar su máximo potencial.
            </p>
            <h3>
                ¿Por qué elegirnos?
            </h3>
            <p>
                Tenemos una amplia experiencia en ayudar a las empresas a crecer su negocio.
                <br />Utilizamos las últimas tendencias del marketing digital para crear estrategias que funcionen.
                <br />Nos apasiona ayudar a las empresas a alcanzar sus objetivos.
            </p>
            <h3>
                ¿Estás listo para crecer tu negocio?
            </h3>
            <p>
                Contacta con Grupo Ward hoy mismo para concertar una consulta gratuita y ver cómo podemos ayudarte.
                <br />¡No esperes más, y empieza a crecer tu negocio hoy mismo!
            </p>
        </div>
    );
}

export default AboutUs;
