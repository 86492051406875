import React from 'react';
import PropTypes from 'prop-types';


const NavLabel = ({iconHTML}) => {
    return (
        <div className="navLabel">
            <div className="mainNav__icon">
                {iconHTML}
            </div>
        </div>
    );
}

NavLabel.propTypes = {
    iconHTML: PropTypes.element,
    title: PropTypes.string.isRequired
}

export default NavLabel;
