import React, {useState} from 'react';
import TwitchEmbed from '../components/pure/twitchEmbed';
// import YtStreamEmbed from '../components/pure/ytStreamEmbed';


const Home = () => {
    // const ytChannelId = "UCELkBrSTz7NWUXeX_X3HfQQ"; // @GrupoWard
    const twitchChannelName = "grupoward";

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const [playerWidth, setPlayerWidth] = useState((
        ((screenWidth - 60) * 0.562) < screenHeight
        ? 
        screenWidth - 60 : (screenWidth - 60) * 0.7
    ));
    const [playerHeight, setPlayerHeight] = useState((
        ((screenWidth - 60) * 0.562) < screenHeight
        ?
        (screenWidth - 60) * 0.562 : (screenWidth - 60) * 0.7 * 0.562
    ));


    return (
        <div>
            <h1>Inicio</h1>
            
            <TwitchEmbed
                channel = {twitchChannelName}
                width = {playerWidth}
                height = {playerHeight}
                withChat = {screenWidth >= 800 ? true : false}
            ></TwitchEmbed>

            {/* YouTube player -> not work*/}
            {/* <iframe
                width = {playerWidth} // 560
                height = {playerHeight} // 316
                src = {`https://www.youtube.com/embed/live_stream?channel=${ytChannelId}`}
                autoPlay = {1}
                allowFullScreen = {true}
            ></iframe> */}
            {/* <YtStreamEmbed channel={ytChannelId}></YtStreamEmbed> */}
        </div>
    );
}

export default Home;
