/* global Twitch */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const TwitchEmbed = ({channel, width, height, withChat}) => {
    
    const handleScriptLoad = () => {
        var embed = new Twitch.Embed("twitch-embed", {
            width: width,
            height: height,
            channel: channel,
            layout: withChat ? "video-with-chat" : "video",
            autoplay: true
        });

        embed.addEventListener(Twitch.Embed.VIDEO_READY, () => {
            var player = embed.getPlayer();
            player.play();
        });
    };
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://embed.twitch.tv/embed/v1.js';
        script.async = true;

        script.addEventListener('load', handleScriptLoad);

        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', handleScriptLoad);
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <div id="twitch-embed"></div>
        </div>
    );
};

TwitchEmbed.propTypes = {
    channel: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    withChat: PropTypes.bool.isRequired
};

export default TwitchEmbed;
