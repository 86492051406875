import '../assets/styles/wardApp.css';
import '../assets/styles/header.css';
import React, {useState, useEffect} from 'react';
// import {useNavigate} from 'react-router-dom';
import MainNav from './container/mainNav';
import Dashboard from './container/dashboard';


const WardApp = () => {
    const [dashboardContent, setdashboardContent] = useState("home");
    // const navigate = useNavigate();

    // useEffect(() => {
    //     navigate("/"+dashboardContent);
    // }, []);

    return (
        <div className='wardApp'>
            <header className='header'>
                <h1>WARD</h1>
            </header>
            <MainNav
                setDashboardContent = { setdashboardContent }
            ></MainNav>
            <Dashboard
                content = {dashboardContent}
            ></Dashboard>
        </div>
    );
}

export default WardApp;
