import '../assets/styles/channelMain.css';
import React from 'react';
import PropTypes from 'prop-types';


const ChannelMain = ({name, description, urls, cafecitoButton}) => {
    return (
        <div className='channel-main'>
            <nav id="navbar-example2" className="navbar bg-black px-3 mb-3">
                <a className="navbar-brand text-danger" href="/">Inicio</a>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a className="nav-link text-danger" href="#scrollspyHeading1">Descripción</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-danger" href="#scrollspyHeading2">Contenido</a>
                    </li>
                    <li className="nav-item">
                        {cafecitoButton && cafecitoButton}
                    </li>
                </ul>
            </nav>

            <div
                data-bs-spy = "scroll"
                data-bs-target = "#navbar-example2"
                data-bs-root-margin = "0px 0px -40%"
                data-bs-smooth-scroll = "true"
                className = "scrollspy-example bg-body-tertiary p-3 rounded-2"
                tabindex = "0"
            >
                <h4 id="scrollspyHeading1">{name}</h4>
                    <p>
                        {description}
                        <br/>
                        <div className='cafecito-button'>
                            {cafecitoButton && cafecitoButton}
                        </div>
                        {
                            urls.donate &&
                            <a
                                className = 'channel-url'
                                href = {urls.donate}
                                rel = 'noreferrer'
                                target = '_blank'
                            > <span className="badge text-bg-danger">Donaciones</span>
                            </a>
                        }
                        {
                            urls.youtube &&
                            <a
                                className = 'channel-url'
                                href = {urls.youtube}
                                rel = 'noreferrer'
                                target = '_blank'
                            > <span className="badge text-bg-danger">YouTube</span>
                            </a>
                        }
                        {
                            urls.spotify &&
                            <a
                                className = 'channel-url'
                                href = {urls.spotify}
                                rel = 'noreferrer'
                                target = '_blank'
                            > <span className="badge text-bg-success">Spotify</span>
                            </a>
                        }
                        {
                            urls.instagram &&
                            <a
                                className = 'channel-url'
                                href = {urls.instagram}
                                rel = 'noreferrer'
                                target = '_blank'
                            > <span className="badge text-bg-info">Instagram</span>
                            </a>
                        }
                    </p>
                <h4 id="scrollspyHeading2">Contenido</h4>
                    <span className="badge text-bg-primary">Próximamente</span>
            </div>
        </div>
    );
}

ChannelMain.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    urls: PropTypes.object,
    cafecitoButton: PropTypes.element,
};

export default ChannelMain;
