import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import './assets/styles/colors.css';
import './assets/styles/width.css';
import './assets/styles/height.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Home from './pages/home';
import Channels from './pages/channels';
import AboutUs from './pages/aboutUs';
import NotFoundPage from './pages/notFoundPage.jsx';
import ChannelMain from './pages/channelMain.jsx';


const recordDescription = `
Record es un programa dedicado a la música. 
Cada episodio se centra en un artista musical diferente, 
ofreciendo una biografía completa de su carrera y trayectoria.
En cada entrega, se abordan los momentos clave de su desarrollo musical, 
desde sus inicios hasta el auge de su popularidad. 
Se cubren detalles sobre su infancia, las influencias que moldearon su estilo, 
los hitos y éxitos de su discografía, así como también los desafíos y obstáculos que tuvo que superar.
Además de la biografía detallada, Record presenta una selección de las canciones más emblemáticas del artista, 
permitiendo a los espectadores disfrutar de su música a lo largo del episodio. 
Con un enfoque informativo pero ameno, Record busca rendir homenaje a los grandes artistas de la música, 
brindando a la audiencia una mirada íntima y completa sobre sus trayectorias. 
Cada entrega es un viaje fascinante a través de la vida y obra de íconos musicales 
que han dejado una huella imborrable.
`;
const recordUrls = {
  // donate: "https://matecito.co/record"
};
const recordCafecitoButton = (
  <a
    href = 'https://cafecito.app/recordar'
    rel = 'noopener'
    target = '_blank'
  >
    <img
      srcset = 'https://cdn.cafecito.app/imgs/buttons/button_5.png 1x, https://cdn.cafecito.app/imgs/buttons/button_5_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_5_3.75x.png 3.75x'
      src = 'https://cdn.cafecito.app/imgs/buttons/button_5.png'
      alt = 'Invitame un café en cafecito.app'
    />
  </a>
);

const avqtpDescription = `
¿A vos qué te parece? es un programa en el cual se alojan recuerdos, filosofías, anécdotas, información 
y mucho disfrute transformado en una conversación entre amigos, 
quienes se suelen ir un poco por las ramas y te invitan constantemente a que te vayas con ellos en cada episodio.
`;
const avqtpUrls = {
  // donate: "https://cafecito.app/avqtp",
  youtube: "https://www.youtube.com/@avosqueteparece",
  spotify: "https://open.spotify.com/show/00XQknwZqNTOgYH86RuezP",
  instagram: "https://www.instagram.com/avqtp_/"
};
const avqtpCafecitoButton = (
  <a
    href = 'https://cafecito.app/avqtp'
    rel = 'noopener'
    target = '_blank'
  >
    <img
      srcset = 'https://cdn.cafecito.app/imgs/buttons/button_5.png 1x, https://cdn.cafecito.app/imgs/buttons/button_5_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_5_3.75x.png 3.75x'
      src = 'https://cdn.cafecito.app/imgs/buttons/button_5.png'
      alt = 'Invitame un café en cafecito.app'
    />
  </a>
);


const router = createBrowserRouter([
  {
    path: "/",
    element: <App></App>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "home",
        element: <Home></Home>
      },
      {
        path: "channels",
        element: <Channels></Channels>
      },
      {
        path: "/about-us",
        element: <AboutUs></AboutUs>
      },
    ]
  },
  {
    path: "avqtp",
    element:
      <ChannelMain
        name = 'AVQTP'
        description = {avqtpDescription}
        urls = {avqtpUrls}
        cafecitoButton = {avqtpCafecitoButton}
      ></ChannelMain>
  },
  {
    path: "sinceramente",
    element: <ChannelMain name='Sinceramente'></ChannelMain>
  },
  {
    path: "record",
    element:
      <ChannelMain
        name = 'Record'
        description = {recordDescription}
        urls = {recordUrls}
        cafecitoButton = {recordCafecitoButton}
      ></ChannelMain>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
