import '../../assets/styles/mainNav.css';
import React from 'react';
import PropTypes from 'prop-types';
import NavLabel from '../pure/navLabel';


const MainNav = ({setDashboardContent}) => {
    return (
        <div className="mainNav">

            {/* HOME icon */}
            <div onClick = {() => setDashboardContent("home")}>
                <NavLabel
                    iconHTML = {<i class="bi bi-house-fill icon"></i>}
                    iconAlt = 'Icono de Inicio'
                    title = 'Inicio'
                ></NavLabel>
            </div>

            {/* CHANNELS icon */}
            <div onClick = {() => setDashboardContent("channels")}>
                <NavLabel
                    iconHTML = {<i class="bi bi-play-circle-fill icon"></i>}
                    iconAlt = 'Icono de canales'
                    title = 'Canales'
                ></NavLabel>
            </div>

            {/* ABOUT US icon */}
            <div onClick = {() => setDashboardContent("about-us")}>
                <NavLabel
                    iconHTML = {<i class="bi bi-question-circle-fill icon"></i>}
                    iconAlt = 'Icono de Sobre Nosotros'
                    title = 'Sobre Nosotros'
                ></NavLabel>
            </div>
        </div>
    );
}

MainNav.propTypes = {
    setDashboardContent: PropTypes.func.isRequired
}

export default MainNav;
