import '../../assets/styles/dashboard.css';
import React from 'react';
import PropTypes from 'prop-types';
import Home from '../../pages/home';
import Channels from '../../pages/channels';
import AboutUs from '../../pages/aboutUs';


const Dashboard = ({content}) => {
    return (
        <div className='dashboard'>
            {content === "home" && <Home></Home>}
            {content === "channels" && <Channels></Channels>}
            {content === "about-us" && <AboutUs></AboutUs>}
        </div>
    );
}

Dashboard.propTypes = {
    content: PropTypes.string.isRequired
}

export default Dashboard;
